import React, { useState } from 'react';
import {
  Box, Button, TextField, Select, MenuItem, FormControl, InputLabel, Card, Typography, CardContent, CardActions
} from '@mui/material';
import Grid from '@mui/material/Grid2';

// Mock flight data
const mockFlights = [
  { id: 1, airline: "Airblue", flightNumber: "PA-001", departure: "10:00 AM", arrival: "12:05 PM", duration: "2h 5m", price: 71, meal: true },
  { id: 2, airline: "Airblue", flightNumber: "PA-007", departure: "05:00 PM", arrival: "07:05 PM", duration: "2h 5m", price: 64, meal: true },
  { id: 3, airline: "Fly Jinnah", flightNumber: "FJ-091", departure: "09:35 AM", arrival: "11:30 AM", duration: "1h 55m", price: 70, meal: false },
  { id: 4, airline: "PIA", flightNumber: "PK-300", departure: "02:00 PM", arrival: "03:55 PM", duration: "1h 55m", price: 69, meal: true },
  { id: 5, airline: "Airsial", flightNumber: "PF-124", departure: "04:00 PM", arrival: "06:00 PM", duration: "2h", price: 72, meal: true },
];

const FlightSearch = () => {
  const [flights] = useState(mockFlights);

  return (
    <Box p={4} maxWidth="1200px" margin="0 auto">
      {/* Search Form */}
      <Grid container spacing={2} mb={4}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField fullWidth label="From" placeholder="Select your origin" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField fullWidth label="To" placeholder="Select your destination" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField fullWidth type="date" label="Date" InputLabelProps={{ shrink: true }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Passengers</InputLabel>
            <Select defaultValue={1}>
              <MenuItem value={1}>1 Adult</MenuItem>
              <MenuItem value={2}>2 Adults</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel>Class</InputLabel>
            <Select defaultValue="Economy">
              <MenuItem value="Economy">Economy</MenuItem>
              <MenuItem value="Business">Business</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button variant="contained" color="primary" fullWidth>
            Search
          </Button>
        </Grid>
      </Grid>

      {/* Flight Results */}
      <Grid container spacing={3}>
        {flights.map((flight) => (
          <Grid item xs={12} key={flight.id}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">
                  {flight.airline} ({flight.flightNumber})
                </Typography>
                <Typography>
                  {flight.departure} - {flight.arrival} ({flight.duration})
                </Typography>
                {flight.meal && <Typography>Meal available</Typography>}
              </CardContent>
              <CardActions>
                <Typography variant="h6" color="primary">
                  ${flight.price}
                </Typography>
                <Button variant="contained" color="primary">
                  Book
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FlightSearch;
