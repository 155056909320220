import React from 'react';
import { CssBaseline, Container, Typography, Box, Paper, Divider, Card, CardContent, CardMedia } from '@mui/material';
import Grid from '@mui/material/Grid2'; 
function AboutUsPage() {
  const values = [
    {
      title: 'Love For Travel',
      description: 'Be mad about your love for travel. Shout it from the rooftops if you must...'
    },
    {
      title: 'Trust',
      description: 'Any and all actions taken in Sastaticket have to consider whether the action builds...'
    },
    {
      title: 'Ownership / Initiative',
      description: 'Take initiative if you see something wrong to fix it and take it to the finish line...'
    }
  ];

  const teamMembers = [
    {
      name: 'Shazil Ali Mehdi',
      position: 'Chief Executive Officer',
      image: '/path/to/image1.jpg'
    },
    {
      name: 'Bilal Mehdi',
      position: 'Co-Founder',
      image: '/path/to/image2.jpg'
    },
    {
      name: 'Shahab Qayyum',
      position: 'Head of Data Science',
      image: '/path/to/image3.jpg'
    },
    {
      name: 'Marvi Abdul Razaque Shaikh',
      position: 'Head of Finance',
      image: '/path/to/image4.jpg'
    }
  ];

  const cultureImages = [
    { src: '/path/to/culture1.jpg', alt: 'Culture 1' },
    { src: '/path/to/culture2.jpg', alt: 'Culture 2' },
    { src: '/path/to/culture3.jpg', alt: 'Culture 3' }
  ];

  const partners = [
    { name: 'HBL', logo: '/path/to/hbl-logo.png' },
    { name: 'Careem', logo: '/path/to/careem-logo.png' },
    { name: 'Wego', logo: '/path/to/wego-logo.png' }
  ];

  return (
    <>
      <CssBaseline />
      <Container>
        {/* About Section */}
        <Box sx={{ py: 5, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>About Us</Typography>
          <Typography variant="body1" sx={{ maxWidth: '800px', margin: 'auto', mb: 4 }}>
            Sastaticket, based in Karachi, Pakistan, is a leading travel platform dedicated to empowering travelers with instant bookings and a wide range of comprehensive choices...
          </Typography>
          <Grid container spacing={5} sx={{ justifyContent: 'center', textAlign: 'left' }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>Our Mission</Typography>
              <Typography variant="body1">
                To empower our customers to travel, experience, and connect with the world.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>Our Vision</Typography>
              <Typography variant="body1">
                To be the go-to travel company for those looking for unique and authentic experiences.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Values Section */}
        <Box sx={{ py: 5 }}>
          <Typography variant="h4" align="center" gutterBottom>Our Values</Typography>
          <Grid container spacing={5} sx={{ mt: 2 }}>
            {values.map((value, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
                  <Typography variant="h6" gutterBottom>{value.title}</Typography>
                  <Typography variant="body2">{value.description}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Journey Section */}
        <Box sx={{ py: 5, textAlign: 'center' }}>
          <Typography variant="h4" align="center" gutterBottom>Our Journey</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, alignItems: 'center' }}>
            <Divider sx={{ flex: 1, height: 2, bgcolor: 'primary.main' }} />
            <Typography variant="body1" sx={{ mx: 3 }}>
              2016 - Sastaticket.pk is launched with a mission to transform travel.
            </Typography>
            <Divider sx={{ flex: 1, height: 2, bgcolor: 'primary.main' }} />
          </Box>
        </Box>

        {/* Management Section */}
        <Box sx={{ py: 5 }}>
          <Typography variant="h4" align="center" gutterBottom>Our Management</Typography>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            {teamMembers.map((member, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card>
                  <CardMedia
                    component="img"
                    height="200"
                    image={member.image}
                    alt={member.name}
                  />
                  <CardContent>
                    <Typography variant="h6" gutterBottom>{member.name}</Typography>
                    <Typography variant="body2" color="text.secondary">{member.position}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Culture Section */}
        <Box sx={{ py: 5 }}>
          <Typography variant="h4" align="center" gutterBottom>Our Culture</Typography>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            {cultureImages.map((image, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <img src={image.src} alt={image.alt} width="100%" height="auto" />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Partners Section */}
        <Box sx={{ py: 5 }}>
          <Typography variant="h4" align="center" gutterBottom>Our Corporate Partners</Typography>
          <Grid container spacing={3} sx={{ mt: 3 }}>
            {partners.map((partner, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Box sx={{ textAlign: 'center' }}>
                  <img src={partner.logo} alt={partner.name} width="100" height="auto" />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default AboutUsPage;
