import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

const BranchCard = ({ branch }) => {
  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Grid container spacing={4}>
        {/* Branch Details */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            {branch.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {branch.address}
          </Typography>
          {branch.uan && (
            <Typography variant="body1">UAN: {branch.uan}</Typography>
          )}
          {branch.whatsapp && (
            <Typography variant="body1">
              WhatsApp: {branch.whatsapp}
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Working Hours: {branch.workingHours}
          </Typography>
        </Grid>

        {/* Google Maps Iframe */}
        <Grid item xs={12} md={8}>
          <Box sx={{ width: '100%', height: '250px' }}>
          {console.log(branch.mapUrl,'123:::')}
          <iframe title={branch.id} src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" width="100%" height="200" frameborder="0"></iframe>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BranchCard;
