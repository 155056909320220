import React from "react";
import { Container, Box, Typography, TextField, Button } from "@mui/material";

function SearchBooking() {
  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb:4,border: "2px solid blue", borderRadius: '20px' }}>
      <Box textAlign="center">
        <Typography variant="h4" gutterBottom>
          Search for your bookings
        </Typography>
        <form>
          <TextField
            fullWidth
            label="Order ID"
            variant="outlined"
            placeholder="e.g. 123456"
            margin="normal"
            helperText="Your Order ID is emailed with booking confirmation."
          />
          <TextField
            fullWidth
            label="Email Address"
            type="email"
            variant="outlined"
            placeholder="e.g. name@gmail.com"
            margin="normal"
            helperText="The email address entered during booking."
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb:2 }}
            fullWidth
          >
            Search
          </Button>
        </form>
      </Box>
    </Container>
  );
}

export default SearchBooking;
