const NotFound = () => {
    return (
      <div style={{ textAlign: 'center', marginTop: '50px', marginBottom: '50px' }}>
        <h1>404 - Page Not Found</h1>
        <p>Oops! The page you are looking for does not exist.</p>
        <a href="/" style={{ color: '#007bff', textDecoration: 'none' }}>
          Go Back to Home
        </a>
      </div>
    );
  };
  
  export default NotFound;
  