import React from "react";
import Search from "./FlightSearchResults";
import CustomerSupport from "./CustomerSupport";
import DownloadApp from "./DownloadApp";
import Reviews from "./Reviews";
import Features from "./Features";
import Statistics from "./Statistics";

const HomePage = () => {
  return (
    <>
      <Search />
      <CustomerSupport />
      <DownloadApp /> 
      <Reviews /> 
      <Features/>
      <Statistics/>
      {/* <Partners /> */}
    </>
  );
};

export default HomePage;
