import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';

const reviewsData = [
  { name: 'Anonymous', comment: 'Great experience sir thank you', time: '54 seconds ago' },
  { name: 'Shaad', comment: 'I am also a customer service executive', location: 'Karachi, Pakistan', time: '2 hours ago' },
  { name: 'Usaid', comment: 'Very good', location: 'Dubai, United Arab Emirates', time: '2 hours ago' },
];

const Reviews = () => {
  return (
    <Box sx={{ padding: 4, backgroundColor: '#f4f6f8' }}>
      <Typography variant="h6" gutterBottom>Excellent ★★★★☆ 4.46 based on 17,325 reviews</Typography>
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        {reviewsData.map((review, index) => (
          <Card key={index} sx={{ flex: 1, minWidth: 250 }}>
            <CardContent>
              <Typography variant="body1" gutterBottom>★★★★★</Typography>
              <Typography variant="body2">{review.comment}</Typography>
              {review.location && <Typography variant="caption" display="block">{review.location}</Typography>}
              <Typography variant="caption">{review.time}</Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default Reviews;
