import React from "react";
import { Box, Typography } from "@mui/material";

const CustomerSupport = () => {
  return (
    <Box sx={{ padding: 2, textAlign: 'center', backgroundColor: '#e8f4f8' }}>
      <Typography variant="h6">24/7 Customer Support</Typography>
      <Typography>Call +92 21-111-172-782 or WhatsApp +92 304 777 7828</Typography>
    </Box>
  );
};

export default CustomerSupport;
