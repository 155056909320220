import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const stats = [
  { label: 'Flights booked', value: '700k+' },
  { label: 'Buses booked', value: '300k+' },
  { label: 'Hotels booked', value: '50k+' },
  { label: 'Kilometers traveled', value: '20m+' },
];

const Statistics = () => {
  return (
    <Box sx={{ padding: 4, backgroundColor: '#f4f6f8' }}>
      <Grid container spacing={4} justifyContent="center">
        {stats.map((stat) => (
          <Grid item xs={12} sm={6} md={3} key={stat.label}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h4" gutterBottom>
                {stat.value}
              </Typography>
              <Typography variant="body1">{stat.label}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Statistics;
