import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  MenuItem,
  IconButton,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const countryCodes = [
    { code: '+93', flag: '🇦🇫', name: 'Afghanistan' },
    { code: '+355', flag: '🇦🇱', name: 'Albania' },
    { code: '+213', flag: '🇩🇿', name: 'Algeria' },
    { code: '+1', flag: '🇺🇸', name: 'United States' },
    { code: '+91', flag: '🇮🇳', name: 'India' },
    { code: '+92', flag: '🇵🇰', name: 'Pakistan' },
    { code: '+44', flag: '🇬🇧', name: 'United Kingdom' },
    { code: '+61', flag: '🇦🇺', name: 'Australia' },
    { code: '+55', flag: '🇧🇷', name: 'Brazil' },
    { code: '+86', flag: '🇨🇳', name: 'China' },
    { code: '+33', flag: '🇫🇷', name: 'France' },
    { code: '+49', flag: '🇩🇪', name: 'Germany' },
    { code: '+39', flag: '🇮🇹', name: 'Italy' },
    { code: '+81', flag: '🇯🇵', name: 'Japan' },
    { code: '+52', flag: '🇲🇽', name: 'Mexico' },
    { code: '+234', flag: '🇳🇬', name: 'Nigeria' },
    { code: '+7', flag: '🇷🇺', name: 'Russia' },
    { code: '+966', flag: '🇸🇦', name: 'Saudi Arabia' },
    { code: '+27', flag: '🇿🇦', name: 'South Africa' },
    { code: '+82', flag: '🇰🇷', name: 'South Korea' },
    { code: '+34', flag: '🇪🇸', name: 'Spain' },
    { code: '+971', flag: '🇦🇪', name: 'United Arab Emirates' },
    { code: '+20', flag: '🇪🇬', name: 'Egypt' },
    { code: '+60', flag: '🇲🇾', name: 'Malaysia' },
    { code: '+62', flag: '🇮🇩', name: 'Indonesia' },
    { code: '+94', flag: '🇱🇰', name: 'Sri Lanka' },
    { code: '+41', flag: '🇨🇭', name: 'Switzerland' },
    { code: '+65', flag: '🇸🇬', name: 'Singapore' },
    { code: '+66', flag: '🇹🇭', name: 'Thailand' },
    { code: '+90', flag: '🇹🇷', name: 'Turkey' },
    { code: '+84', flag: '🇻🇳', name: 'Vietnam' },
    { code: '+351', flag: '🇵🇹', name: 'Portugal' },
    { code: '+420', flag: '🇨🇿', name: 'Czech Republic' },
    { code: '+386', flag: '🇸🇮', name: 'Slovenia' },
    { code: '+47', flag: '🇳🇴', name: 'Norway' },
    { code: '+358', flag: '🇫🇮', name: 'Finland' },
    { code: '+46', flag: '🇸🇪', name: 'Sweden' },
    { code: '+31', flag: '🇳🇱', name: 'Netherlands' },
    { code: '+32', flag: '🇧🇪', name: 'Belgium' },
    { code: '+48', flag: '🇵🇱', name: 'Poland' },
    { code: '+43', flag: '🇦🇹', name: 'Austria' },
    { code: '+351', flag: '🇵🇹', name: 'Portugal' },
    { code: '+1', flag: '🇨🇦', name: 'Canada' },
    { code: '+61', flag: '🇦🇺', name: 'Australia' },
    { code: '+64', flag: '🇳🇿', name: 'New Zealand' },
    { code: '+63', flag: '🇵🇭', name: 'Philippines' },
    { code: '+374', flag: '🇦🇲', name: 'Armenia' },
    { code: '+94', flag: '🇱🇰', name: 'Sri Lanka' },
    { code: '+372', flag: '🇪🇪', name: 'Estonia' },
    { code: '+994', flag: '🇦🇿', name: 'Azerbaijan' },
    { code: '+91', flag: '🇮🇳', name: 'India' },
    { code: '+92', flag: '🇵🇰', name: 'Pakistan' },
    { code: '+880', flag: '🇧🇩', name: 'Bangladesh' },
  ];
  
  const SignInDialog = (props) => {
    const { open, setOpen } = props;
    const [countryCode, setCountryCode] = useState('+92'); // Default country code
    const [mobileNumber, setMobileNumber] = useState('');
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleCountryCodeChange = (e) => {
      setCountryCode(e.target.value);
    };
  
    const handleMobileChange = (e) => {
      setMobileNumber(e.target.value);
    };
  
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
          <DialogTitle>Continue to your account</DialogTitle>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
  
        <DialogContent dividers>
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img
              src="/tourist_explore.svg" // Replace this with the illustration image
              alt="Travel Illustration"
              style={{ width: '100%' }}
            />
          </div>
  
          {/* Combined Country Code + Mobile Number TextField */}
          <TextField
          label="Mobile Number"
          value={mobileNumber}
          onChange={handleMobileChange}
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  select
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                  variant="standard"
                  style={{
                    width: '80px',
                    border: 'none',
                    padding: '0px',
                    margin: '0px',
                  }}
                  SelectProps={{
                    style: {
                      paddingRight: '0px',
                    },
                  }}
                  InputProps={{
                    disableUnderline: true, // Remove underline
                  }}
                >
                  {countryCodes.map((option) => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.flag} {option.code}
                    </MenuItem>
                  ))}
                </TextField>
              </InputAdornment>
            ),
          }}
        />
  
          <Button
            variant="contained"
            fullWidth
            style={{ marginTop: '16px', backgroundColor: '#E1E4EB' }}
            disabled={mobileNumber.length < 10}  // Adjust the number length for validation
          >
            Continue
          </Button>
  
          <div style={{ marginTop: '8px', textAlign: 'center', fontSize: '12px' }}>
            By continuing you are agreeing to the{' '}
            <a href="/terms-and-conditions" style={{ textDecoration: 'none', color: '#1976d2' }}>
              Terms and Conditions
            </a>{' '}
            of Sastaticket.pk.
          </div>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default SignInDialog;