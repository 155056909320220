import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CustomRadioGroup from "../../reusablecomponents/CustomRadioGroup";

const Search = () => {
  const [classType, setClassType] =useState("Economy");
  const [passengers, setPassengers] = useState(1);
  const [fromLocation, setFromLocation] = useState("");
  const [toLocation, setToLocation] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [tripType, setTripType] = useState('One Way');
  
  const options = ['One Way', 'Return', 'Multi City'];

  const handleSearch = () => {
    // Logic for filtering results can be added here
    console.log({
      tripType,
      fromLocation,
      toLocation,
      departureDate,
      returnDate,
      passengers,
      classType,
    });
  };

  return (
    <Box
      sx={{
        padding: 4, backgroundColor: "#f4f6f8",
        backgroundImage: "url('/filter-bg.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Box       sx={{ padding: '10px',borderRadius: "4px",backgroundColor: "#f4f6f8",}}>
      {/* Trip Type Radio Buttons */}
      <CustomRadioGroup
      options={options}
      value={tripType}
      onChange={setTripType}
      name="tripType"
      label="trip-type"
    />

      {/* Search Form */}
      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
        {/* From Location */}
        <TextField
          label="From (City or Airport)"
          variant="outlined"
          value={fromLocation}
          onChange={(e) => setFromLocation(e.target.value)}
          sx={{ flex: 1 }}
        />

        {/* To Location */}
        <TextField
          label="To (City or Airport)"
          variant="outlined"
          value={toLocation}
          onChange={(e) => setToLocation(e.target.value)}
          sx={{ flex: 1 }}
        />

        {/* Departure Date */}
        <TextField
          label="Departure Date"
          variant="outlined"
          type="date"
          value={departureDate}
          onChange={(e) => setDepartureDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          sx={{ flex: 1 }}
        />

        {/* Return Date (only shown when Return or Multi City selected) */}
        {(tripType === "Return" || tripType === "Multi City") && (
          <TextField
            label="Return Date"
            variant="outlined"
            type="date"
            value={returnDate}
            onChange={(e) => setReturnDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ flex: 1 }}
          />
        )}
      </Box>

      {/* Class Type and Passengers */}
      <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
        {/* Passengers */}
        <FormControl sx={{ flex: 1 }}>
          <InputLabel>Passengers</InputLabel>
          <Select
            value={passengers}
            label="Passengers"
            onChange={(e) => setPassengers(e.target.value)}
          >
            {[...Array(10).keys()].map((i) => (
              <MenuItem key={i + 1} value={i + 1}>
                {i + 1} Passenger(s)
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Class Type */}
        <FormControl sx={{ flex: 1 }}>
          <InputLabel>Class</InputLabel>
          <Select
            value={classType}
            label="Class"
            onChange={(e) => setClassType(e.target.value)}
          >
            <MenuItem value="Economy">Economy</MenuItem>
            <MenuItem value="Business">Business</MenuItem>
            <MenuItem value="First Class">First Class</MenuItem>
          </Select>
        </FormControl>

        {/* Search Flights Button */}
        <Button
          variant="contained"
          color="primary"
          sx={{ flex: 1 }}
          onClick={handleSearch}
        >
          Search Flights
        </Button>
      </Box>
      </Box>
    </Box>
  );
};

export default Search;
