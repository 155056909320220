import React from 'react';
import { Box, Typography } from '@mui/material';
import SupportIcon from '@mui/icons-material/Support'; // You can replace these with your own icons
import RefundIcon from '@mui/icons-material/AttachMoney';
import SecurityIcon from '@mui/icons-material/Security';

const features = [
  { icon: <SupportIcon />, title: '24/7 Customer Support' },
  { icon: <RefundIcon />, title: 'Refunds within 48 hours' },
  { icon: <SecurityIcon />, title: 'Secure Transaction Guaranteed' },
];

const Features = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-around', padding: 4 }}>
      {features?.map((feature, index) => (
        <Box key={index} sx={{ textAlign: 'center' }}>
          {feature.icon}
          <Typography variant="h6" gutterBottom>{feature.title}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Features;
