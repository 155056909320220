import React from 'react';
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';

// Custom styled FormControlLabel to look like a button
const StyledFormControlLabel = styled(FormControlLabel)(({ theme, selected }) => ({
  borderRadius: '20px',
  border: `2px solid ${selected ? theme.palette.primary.main : theme.palette.grey[400]}`,
  backgroundColor: selected ? theme.palette.primary.light : 'transparent',
  padding: theme.spacing(1, 2),
  margin: theme.spacing(0.5),
  transition: 'background-color 0.3s, border-color 0.3s',
  '& .MuiTypography-root': {
    fontWeight: selected ? 'bold' : 'normal',
    color: selected ? theme.palette.primary.main : theme.palette.text.primary,
  },
}));

// Reusable Custom RadioGroup Component
const CustomRadioGroup = ({ options, value, onChange, name, label }) => {
  return (
    <FormControl component="fieldset" sx={{ marginBottom: 2 }}>
      <RadioGroup
        row
        aria-label={label}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((option) => (
          <StyledFormControlLabel
            key={option}
            value={option}
            control={<Radio sx={{ display: 'none' }} />} // Hide the circle radio button
            label={option}
            selected={value === option} // Highlight the selected option
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default CustomRadioGroup;
