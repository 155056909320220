import React from "react";
import { Box, Typography, Link } from "@mui/material";
import Grid from '@mui/material/Grid2';
const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#0b3d91",
        color: "#fff",
        padding: "40px 20px", // Adjust padding as needed
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container spacing={3} sx={{ maxWidth: 1200 }}>
        <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "15px" }}>
            Sastaticket.pk
          </Typography>
          <Typography variant="body2">
            Your one-stop solution for travel bookings.
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ marginBottom: "15px" }}>
            Useful Links
          </Typography>
          <Link href="/aboutus" color="inherit" sx={{ display: "block", marginBottom: "5px" }}>
            About Us
          </Link>
          <Link href="/services" color="inherit" sx={{ display: "block", marginBottom: "5px" }}>
            Services
          </Link>
          <Link href="/contactus" color="inherit" sx={{ display: "block", marginBottom: "5px" }}>
            Contact
          </Link>
          <Link href="/privacypolicy" color="inherit" sx={{ display: "block", marginBottom: "5px" }}>
            Privacy Policy
          </Link>
        </Grid>
        <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ marginBottom: "15px" }}>
            Contact Us
          </Typography>
          <Typography variant="body2">Email: info@sastaticket.pk</Typography>
          <Typography variant="body2">Phone: +92 123 456 789</Typography>
        </Grid>
      </Grid>
      <Typography variant="body2" sx={{ marginTop: "20px" }}>
        © {new Date().getFullYear()} Sastaticket.pk. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
