import React from "react";
import { Box, TextField, Button, Typography } from "@mui/material";

const DownloadApp = () => {
  return (
    <Box sx={{ padding: 2, textAlign: 'center' }}>
      <Typography variant="h6">Download App & Save PKR 500</Typography>
      <TextField
        variant="outlined"
        placeholder="+92 333 0000000"
        sx={{ width: '40%', marginTop: 2 }}
      />
      <Button variant="contained" color="primary" sx={{ marginLeft: 2 }}>
        Get App Link
      </Button>
    </Box>
  );
};

export default DownloadApp;
