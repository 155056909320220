import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu"; // MUI Menu icon
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff"; // MUI Flight icon
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus"; // MUI Bus icon
import SignInDialog from "../pages/SignIn/SignInPage";
import CloseIcon from "@mui/icons-material/Close";
import { Call, WhatsApp } from "@mui/icons-material";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);

  const [currency, setCurrency] = useState("USD");

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleNavigation = (path) => {
    window.location.href = path; // Change the URL path
  };

  const drawerItems = [
    { text: "Our Branches", path: "/contactus" },
    { text: "Flights", path: "/flight" },
    { text: "Signin/Login", path: "/signIn" },
  ];

  const currencies = [
    { value: "PKR", label: "PKR" },
    { value: "AED", label: "AED" },
    { value: "USD", label: "USD" },
    { value: "SAR", label: "SAR" },
    { value: "GBP", label: "GBP" },
  ];

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };
  return (
    <>
      <SignInDialog
        open={openSignInDialog}
        setOpen={setOpenSignInDialog}
        onClose={() => setOpenSignInDialog(false)}
      />
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar sx={{ justifyContent: "space-between", padding: "0 20px" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ flexGrow: 1, fontWeight: "bold", fontSize: "24px" }}
          >
            <span
              style={{
                textDecoration: "none",
                color: "#0b3d91",
                cursor: "pointer",
              }}
              onClick={() => handleNavigation("/")}
            >
              Sastaticket.pk
            </span>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* Search Bookings */}
            <Button
              color="inherit"
              onClick={() => handleNavigation("/search-booking")}
              startIcon={<FlightTakeoffIcon />}
              sx={{ margin: "0 10px" }}
            >
              Search Bookings
            </Button>

            {/* Contact Information */}
            <Button
              color="inherit"
              onClick={() => handleNavigation("/buses")}
              startIcon={<Call />}
              sx={{ margin: "0 10px" }}
              href="tel:+9221111172782"
            >
              Call 24/7: +92 21-111-172-782
            </Button>
            <Button
              color="inherit"
              onClick={() => handleNavigation("/")}
              startIcon={<WhatsApp />}
              sx={{ margin: "0 10px" }}
              href="https://wa.me/923047772782"
            >
              WhatsApp 24/7: +92 304-777-2782
            </Button>

            {/* Currency Dropdown */}
            <FormControl variant="outlined" size="small" sx={{ mx: 2 }}>
              <InputLabel>Currency</InputLabel>
              <Select
                value={currency}
                onChange={handleCurrencyChange}
                label="Currency"
              >
                {currencies.map((currency) => (
                  <MenuItem key={currency.value} value={currency.value}>
                    {currency.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Sign In Button */}
            <Button
              color="inherit"
              onClick={() => setOpenSignInDialog(true)}
              startIcon={<DirectionsBusIcon />}
              sx={{ margin: "0 10px" }}
            >
              Sign In
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            marginTop: "64px", // Set the marginTop
            width: "250px", // Set the width
          },
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}
        >
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {drawerItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleNavigation(item.path)}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Header;
